module.exports = [
    {
        minPrice: 1,
        maxPrice: 5000,
        value: '5klo',
    },
    {
        minPrice: 5001,
        maxPrice: 10000,
        value: '5khi',
    },
    {
        minPrice: 10001,
        maxPrice: 15000,
        value: '10klo',
    },
    {
        minPrice: 15001,
        maxPrice: 20000,
        value: '10khi',
    },
    {
        minPrice: 20001,
        maxPrice: 25000,
        value: '20klo',
    },
    {
        minPrice: 25001,
        maxPrice: 30000,
        value: '20khi',
    },
    {
        minPrice: 30001,
        maxPrice: 35000,
        value: '30klo',
    },
    {
        minPrice: 35001,
        maxPrice: 40000,
        value: '30khi',
    },
    {
        minPrice: 40001,
        maxPrice: 45000,
        value: '40klo',
    },
    {
        minPrice: 45001,
        maxPrice: 50000,
        value: '40khi',
    },
    {
        minPrice: 50001,
        maxPrice: 55000,
        value: '50klo',
    },
    {
        minPrice: 55001,
        maxPrice: 60000,
        value: '50khi',
    },
    {
        minPrice: 60001,
        maxPrice: 65000,
        value: '60klo',
    },
    {
        minPrice: 65001,
        maxPrice: 70000,
        value: '60khi',
    },
    {
        minPrice: 70001,
        maxPrice: null,
        value: 'nolimit',
    },

];
