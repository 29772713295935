import _get from 'lodash/get';

import tablePrices from '@/config/vehiclePriceRange';

function buildBodyStyle(filters) {
    const options = _get(filters, 'vehicleStyleCodes.options') || _get(filters, 'vehicleStyleCode.options', []);
    let bodyStyles = options.filter((option) => option.selected);

    if (bodyStyles.length === 0) {
        bodyStyles = options.filter((option) => !option.disabled);
    }
    const bodyStyle = bodyStyles.map((option) => {
        const { value } = option;
        if (value === 'VANMV') return 'VANS';
        if (value === 'SUVCROSS') return 'SUV';
        return value;
    }).join(',');

    return bodyStyle;
}

function buildMdlMdl2({ modelCodeList, modelCode } = {}, filterGroups) {
    let mdl = 'none';
    let mdl2 = 'unk';
    let mke;

    const modelCodes = modelCodeList || modelCode;
    const modelCodeKey = filterGroups && filterGroups.modelCode ? 'modelCode' : 'modelCodeList';

    if (modelCodes) {
        const models = Array.isArray(modelCodes) ? modelCodes : modelCodes.split(',');
        const [firstModel, ...additionalModels] = models;
        if (firstModel) {
            mdl = firstModel;
        }
        if (additionalModels.length > 0) {
            mdl2 = additionalModels.join(',');
        }
    }

    if (mdl !== 'none' && filterGroups && filterGroups[modelCodeKey]) {
        // Build mke (make) to match with mdl (model)
        const { [modelCodeKey]: filteredModels } = filterGroups;
        Object.keys(filteredModels).forEach((model) => {
            if (_get(filteredModels, `${model}.value`, []).includes(mdl)) {
                mke = model;
            }
        });
    }

    return { mdl, mdl2, mke };
}

function buildPrcrn(query) {
    const caculatePrice = (minInput, maxInput) => {
        const result = [];
        const { length } = tablePrices;

        for (let i = 0; i < length; ++i) {
            const { maxPrice, value } = tablePrices[i];

            if (!maxPrice || minInput <= maxPrice) {
                result.push(value);
            }

            if (maxInput <= maxPrice) {
                break;
            }
        }

        return result;
    };

    const generatePrcrn = (prices) => prices.join(',');

    const {
        minPrice,
        maxPrice,
    } = query;

    const currentMinPriceValue = (!minPrice || minPrice === 0) ? tablePrices[0].minPrice : minPrice;
    const currentMaxPriceValue = (!maxPrice || maxPrice === 0) ? tablePrices[tablePrices.length - 1].minPrice : maxPrice;

    const valuePrices = caculatePrice(currentMinPriceValue, currentMaxPriceValue);

    return generatePrcrn(valuePrices);
}

// TODO remove this utility after enabled_ads_migration flag is successfully live at 100%
export default function updatePageLevelTargeting(ad, filters, { query, filterGroups } = {}) {
    // Construct bodyStyles
    const newAd = { ...ad };
    const bodyStyle = buildBodyStyle(filters);

    // Construct mdl, mdl2, mke
    const { mdl: newModelCode, mdl2, mke } = buildMdlMdl2(query, filterGroups);
    const modelCodeParam = _get(ad, 'pageLevelTargeting.mdl', '') || newModelCode;

    // Construct prcrn
    const prcrn = buildPrcrn(query);

    // Update pageLevelTargeting
    const { pageLevelTargeting = {} } = ad;

    if (!pageLevelTargeting.psid || pageLevelTargeting.psid === 'nl') {
        delete pageLevelTargeting.psid;
    }

    if (!pageLevelTargeting.siomid || pageLevelTargeting.siomid === 'nl') {
        delete pageLevelTargeting.siomid;
    }

    newAd.pageLevelTargeting = {
        ...pageLevelTargeting,
        bstyl: bodyStyle.length > 0 ? bodyStyle : pageLevelTargeting.bstyl,
        mdl: modelCodeParam,
        mdl2,
        mke: mke || pageLevelTargeting.mke,
        prcrn,
    };

    return newAd;
}
