import fordSeoUrl from '@/config/seo-config-url/branded/ford';

export default {
    kbb: {
        srp: {
            metadesc: 'Shop, watch video walkarounds and compare prices on @@name@@ listings in @@city@@, @@state@@. See Kelley Blue Book pricing to get the best deal.',
            metadescnationalextra: 'Find @@name@@ for Sale @@owner@@@@price@@@@mpg@@, including @@extra@@.',
            metadescnational: 'Shop, watch video walkarounds and compare prices on @@name@@ listings. See Kelley Blue Book pricing to get the best deal.',
            metadescextra: 'Shop, watch video walkarounds and compare prices on @@name@@ listings in @@city@@, @@state@@ including @@extra@@. See Kelley Blue Book pricing to get the best deal.',
        },
        vdp: {
            metadesc: '@@name@@ for sale in @@city@@, @@state@@. Learn more about this vehicle.',
            metadescnationalextra: '@@name@@ for sale in @@city@@, @@state@@. Learn more about this vehicle.',
            metadescnational: '@@name@@ for sale in @@city@@, @@state@@. Learn more about this vehicle.',
            metadescextra: '@@name@@ for sale in @@city@@, @@state@@. Learn more about this vehicle.',
        },
    },
    atc: {
        sfp: {
            metadesc: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescnationalextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescnational: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metakeywords: 'cars for sale, buy a car, car sale, auto classifieds, car search engine',
        },
        srp: {
            metadesc: 'Test drive @@name@@ at home in @@city@@, @@state@@.',
            metadescnationalextra: 'See good deals, great deals and more on @@name@@, including @@extra@@.',
            metadescnational: 'Test drive @@name@@ at home from the top dealers in your area.',
            metadescextra: 'See good deals, great deals and more on @@name@@ in @@city@@, @@state@@, including @@extra@@.',
        },
        vdp: {
            metadesc: '@@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on Autotrader.',
            metadescnationalextra: '@@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on Autotrader.',
            metadescnational: '@@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on Autotrader.',
            metadescextra: '@@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on Autotrader.',
        },
        searchform: {
            metadesc: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescnationalextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescnational: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metadescextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            metakeywords: 'cars for sale, buy a car, car sale, auto classifieds, car search engine',
        },
    },
    ford: {
        fordlanding: {
            metadesc: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
            metadescnationalextra: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
            metadescnational: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
            metadescextra: 'Search certified used cars, trucks, SUVs, and commercial vehicles backed by Ford. Shop certified used vehicles for sale near you.',
        },
        vdp: {
            metadesc: `Certified Used @@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on ${fordSeoUrl.rootDomain}.`,
            metadescnationalextra: `Certified Used @@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on ${fordSeoUrl.rootDomain}.`,
            metadescnational: `Certified Used @@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on ${fordSeoUrl.rootDomain}.`,
            metadescextra: `Certified Used @@name@@ for sale near you in @@city@@, @@state@@. Get more information and car pricing for this vehicle on ${fordSeoUrl.rootDomain}.`,
        },
        moneyBackGuarantee: {
            metadesc: 'Purchase confidently with Ford Blue Advantage\'s Money Back Guarantee. Shop certified used cars, trucks, and SUVs with a money back guarantee.',
            metadescnationalextra: 'Purchase confidently with Ford Blue Advantage\'s Money Back Guarantee. Shop certified used cars, trucks, and SUVs with a money back guarantee.',
            metadescnational: 'Purchase confidently with Ford Blue Advantage\'s Money Back Guarantee. Shop certified used cars, trucks, and SUVs with a money back guarantee.',
            metadescextra: 'Purchase confidently with Ford Blue Advantage\'s Money Back Guarantee. Shop certified used cars, trucks, and SUVs with a money back guarantee.',
        },
        commercialVehicles: {
            metadesc: 'Find certified used commercial vehicles for sale, like Ford Transit vans and commercial trucks. Ford Blue Advantage vehicles pass a multi-point inspection.',
            metadescnationalextra: 'Find certified used commercial vehicles for sale, like Ford Transit vans and commercial trucks. Ford Blue Advantage vehicles pass a multi-point inspection.',
            metadescnational: 'Find certified used commercial vehicles for sale, like Ford Transit vans and commercial trucks. Ford Blue Advantage vehicles pass a multi-point inspection.',
            metadescextra: 'Find certified used commercial vehicles for sale, like Ford Transit vans and commercial trucks. Ford Blue Advantage vehicles pass a multi-point inspection.',
        },
    },
};
