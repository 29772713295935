import { testUrlLocation } from '@/utilities';

import { srpLocationTypeDuck } from '@/ducks/srp';

export default function withLocationType() {
    return async (ctx) => {
        ctx.match.isNational = !(await testUrlLocation(ctx.match.url));
        ctx.store.dispatch(srpLocationTypeDuck.creators.set(ctx.match.isNational));
    };
}
