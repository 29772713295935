import { objectDuckCreator } from '@atc/modular-redux';

import { asfDuckPreset } from '@/ducks/presets';

const sfpAdsDuck = objectDuckCreator({
    ...asfDuckPreset,
    store: 'ad',
    initialState: {
        pageLevelTargeting: {},
        adUnit: '',
        prebidAdapters: [],
    },
});

export default sfpAdsDuck;
