module.exports = {
    type: 'pagetitle',
    kbb: {
        srp: {
            pageheadingnational: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@ Near Me',
            pageheading: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
    },
    ford: {
        srp: {
            pageheadingnational: 'Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@',
            pageheading: 'Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
        moneyBackGuarantee: {
            pageheadingnational: 'Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@',
            pageheading: 'Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
        commercialVehicles: {
            pageheadingnational: 'Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@',
            pageheading: 'Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
    },
    demo: {
        srp: {
            pageheadingnational: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@',
            pageheading: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
    },
    atc: {
        srp: {
            pageheadingnational: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@',
            pageheading: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@',
        },
    },
};
