export default function addReqProps() {
    return (ctx) => {

        // Enhance the responseData with extra fields.  ex: reqHost is used to qualify some relative urls
        let reqProtocol = 'http';

        if (ctx.req && ((ctx.req.headers && (ctx.req.headers['x-forwarded-proto'] === 'https' || ctx.req.headers.atc_ssl === 'true')) || ctx.req.protocol === 'https')) {
            reqProtocol = 'https';
        }

        const reqHost = typeof window !== 'undefined' ? window.location.origin : `${reqProtocol}://${ctx.req.headers.host}`;
        const reqUrl = typeof window !== 'undefined' ? window.location.pathname + (window.location.search || '') : ctx.req.url;

        Object.assign(
            ctx.data,
            {
                reqHost,
                reqUrl,
            },
        );
    };
}
