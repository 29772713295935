import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

export default function withClientPEData() {
    return async (ctx) => {
        // We have turned off the SSR fetch of PE data and rely on the client side fetch of it on initial mount
        // we want to only dispatch the client side pe data if it exists in local storage on subsequent client page loads
        if (typeof window !== 'undefined') {
            const peData = window.localStorage.getItem('pe_data');
            if (peData) {
                ctx.store.dispatch(PersonalizationEngineModule.duck.creators.assign(JSON.parse(peData)));
            }
        }
    };
}
