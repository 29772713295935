//  TODO:
// ATC doesn't support SPA at this stage
// There is an error thrown when this import is processed that we need to investigate
// import { push } from '@bonnet/next/router';

/**
 * Legacy Base endpoints may have returned a 'redirectDestination'
 * If that is detected we should redirect on server or client
 * and cancel the rest of the middleware processing
 */
export default function handleLegacyServerRedirect() {
    return (ctx, next) => {
        if (ctx.data && ctx.data.redirectDestination) {
            const redirectUrl = ctx.data.redirectDestination;
            const statusCode = 302;

            if (ctx.res) {
                ctx.res.redirect(statusCode, redirectUrl);
                ctx.res.end();
            } else {
                // TODO: See above TODO for details
                // ctx.store.dispatch(push(redirectUrl));
            }

            ctx.statusCode = statusCode;

            // fire next to bypass the remaining queue of context middleware
            next('server redirect');
        }
    };
}
