import { createAction, createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import updatePageLevelTargeting from '@/utilities/updatePageLevelTargeting';

import { srpDuckPreset } from '@/ducks/presets';

const srpAdsDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'ad',
    initialState: {
        pageLevelTargeting: {},
        adUnit: '',
        prebidAdapters: [],
        adsLazyLoadingOffset: '',
    },
}).extend({
    types: [
        'SET_PAGE_LEVEL_TARGETING',
    ],
    creators: ({ types }) => ({
        setPageLevelTargeting: createAction(types.SET_PAGE_LEVEL_TARGETING),
    }),
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_PAGE_LEVEL_TARGETING: {

                const { filters, query, filterGroups, ...rest } = action.payload;

                return {
                    ...state,
                    ...updatePageLevelTargeting(rest, filters, { query, filterGroups }),
                };
            }

            default:
                return state;
        }
    },
    selectors: () => ({
        getAdsLazyLoadingOffset: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ adsLazyLoadingOffset }) => adsLazyLoadingOffset,
        )),
    }),
});

export default srpAdsDuck;
