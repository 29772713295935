import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { asfDuckPreset } from '@/ducks/presets';

const sfpSelectedCriteriaDuck = objectDuckCreator({
    ...asfDuckPreset,
    store: 'selectedCriteria',
    initialState: {},
}).extend({
    selectors: (duck) => ({
        getNormalizedCriteria: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            (criteria) => {
                const selectedCriteria = { ...criteria };

                // Backend doesn't handle empty values, so completely remove these if they are empty
                if (selectedCriteria.minPrice === '') {
                    delete selectedCriteria.minPrice;
                }
                if (selectedCriteria.maxPrice === '') {
                    delete selectedCriteria.maxPrice;
                }

                return selectedCriteria;
            }
        )),
    }),
});

export default sfpSelectedCriteriaDuck;
