import { getPath } from '@atc/bonnet-paths';

export default function withCurrentUrl() {
    return async (ctx) => {
        const {
            req,
            pageName = '',
            query = {},
            data: { brand },
        } = ctx;

        // Builds current URL
        const currentUrl = await getPath(pageName, query, {
            absolute: true,
            target: 'cs',
            req,
            brand,
            useUpdatedVdpPath: true,
        });

        Object.assign(ctx.data, { currentUrl });
    };
}
