import { getOptimizelyInstance } from 'reaxl-optimizely';

// convert string from snake_case to camelCase
const snakeToCamel = (str = '') => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

// duplicates snakecase keyed properties with camelCase keyed properties
const duplicatePropertiesWithCamelCase = (acc, [key, value]) => {
    acc[key] = value;

    if (key.includes('-') || key.includes('_')) {
        acc[snakeToCamel(key)] = value;
    }

    return acc;
};

// adds useFeatures functionality to the ctx object
export default function withUseFeatures() {
    return async (ctx) => {

        const { configFeatures: config = {}, optimizelyDynamicConfig = {} } = ctx.data;

        // looks up singular feature key
        ctx.useFeature = (key) => {

            const { user = {}, forcedFeatures: forcedOptimizelyFeatures = {} } = optimizelyDynamicConfig;

            // default feature to NOT enabled
            let isFeatureEnabled = false;
            let featureVariables = {};

            try {
                const optimizely = getOptimizelyInstance();
                const { featuresMap: optimizelyFeatures = {} } = optimizely.getOptimizelyConfig();

                if (forcedOptimizelyFeatures[key] !== undefined && optimizelyFeatures[key] || (optimizely && optimizelyFeatures[key] && optimizely.isFeatureEnabled(key, user.id, user.attributes))) {
                    // if an optimizely feature exists and is enabled we set it to true
                    if (forcedOptimizelyFeatures[key] !== false) {
                        isFeatureEnabled = true;
                        featureVariables = {
                            ...optimizely.getAllFeatureVariables(key, user.id, user.attributes),
                            ...forcedOptimizelyFeatures[key],
                        };
                    }

                } else if (forcedOptimizelyFeatures[key] && config[key]) {
                    const { variables: configVariables = {} } = config[key];
                    isFeatureEnabled = true;
                    featureVariables = {
                        ...configVariables,
                        ...forcedOptimizelyFeatures[key],
                    };

                } else if (forcedOptimizelyFeatures[key] === undefined && config[key]) {
                    const { enabled: configEnabled, variables: configVariables = {} } = config[key];
                    isFeatureEnabled = configEnabled;
                    featureVariables = configVariables;
                }

                // duplicate snake_case property keys
                featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

                return [isFeatureEnabled, featureVariables];
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('ERROR: withUseFeatures - ', error);
            }

            // if Optimizely errors out, build useFeatures with just brand config data
            if (forcedOptimizelyFeatures[key] && config[key]) {
                const { variables: configVariables = {} } = config[key];
                isFeatureEnabled = true;
                featureVariables = {
                    ...configVariables,
                    ...forcedOptimizelyFeatures[key],
                };

            } else if (forcedOptimizelyFeatures[key] === undefined && config[key]) {
                const { enabled: configEnabled, variables: configVariables = {} } = config[key];
                isFeatureEnabled = configEnabled;
                featureVariables = configVariables;
            }

            // duplicate snake_case property keys
            featureVariables = Object.entries(featureVariables).reduce(duplicatePropertiesWithCamelCase, {});

            // return with just brand config data if optimizely throws an error
            return [isFeatureEnabled, featureVariables];
        };

        ctx.useFeatures = (keys = []) => {

            const features = [].concat(keys).reduce((acc, key) => ({
                ...acc,
                [key]: ctx.useFeature(key),
            }), {});

            // duplicate snake_case property keys
            return Object.entries(features).reduce(duplicatePropertiesWithCamelCase, {});
        };
    };
}
