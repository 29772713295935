import { objectDuckCreator } from '@atc/modular-redux';

import { vdpDuckPreset } from '@/ducks/presets';

const vdpAdsDuck = objectDuckCreator({
    ...vdpDuckPreset,
    store: 'ad',
    initialState: {
        pageLevelTargeting: {},
        adUnit: '',
        prebidAdapters: [],
    },
});

export default vdpAdsDuck;
